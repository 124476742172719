import styled from "styled-components";

import { BREAKPOINT } from "../../consts/consts";
import { COLORS } from "../../theme/colors";
import { spacing } from "../../theme/spacing";
import { TYPOGRAPHY } from "../../theme/typography";

export const FooterStyled = styled.footer`
  align-items: center;
  border-top: 1px solid ${COLORS.light};
  color: ${COLORS.dark};
  display: flex;
  justify-content: flex-start;
  margin: ${spacing(8)} auto 0;
  max-width: ${spacing(150)};
  padding: ${spacing(5)} 0; 
  text-align: left;
  width: 100%;

  @media screen and (max-width: ${BREAKPOINT}) {
    margin: 0 ${spacing(2)};
    width: calc(100% - 32px);
  }
`;

export const FooterInner = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  width: 100%;

  @media screen and (max-width: ${BREAKPOINT}) {
    flex-direction: column;
  }
`;

export const CookiesLink = styled.button`
  background-color: none;
  background-color: transparent;
  border: none;
  color: ${COLORS.dark};
  cursor: pointer;
  font-size: ${TYPOGRAPHY.small};
  text-decoration: underline;
`;
