import styled from "styled-components";

import { BREAKPOINT } from "../../consts/consts";
import { spacing } from "../../theme/spacing";

export const ColStyled = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0 ${spacing(4)};
  width: 50%;

  &:first-of-type {
    padding-left: 0;
  }

  &:last-of-type {
    padding-right: 0;
  }

  @media screen and (max-width: ${BREAKPOINT}) {
    width: 100%;
    order: ${({ order }) => order};
    padding: 0 ${spacing(2)};

    &:first-of-type,
    &:last-of-type {
      padding: 0 ${spacing(2)};
    }
  }
`;
