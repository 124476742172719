import styled from "styled-components";

import { BREAKPOINT } from "../../../consts/consts";
import { COLORS } from "../../../theme/colors";
import { spacing } from "../../../theme/spacing";
import { TYPOGRAPHY } from "../../../theme/typography";

export const HeroContentStyled = styled.div`
  color: ${({ $invert }) => ($invert ? COLORS.dark : COLORS.white)};
  font-size: ${TYPOGRAPHY.large};
  margin-top: ${spacing(-1)};
  max-width: ${spacing(50)};
  position: relative;
  text-align: left;
  top: ${({ $invert }) => ($invert ? 0 : spacing(14))};
  width: 100%;

  a {
    color: ${({ $invert }) => ($invert ? COLORS.dark : COLORS.white)};
    text-decoration: none;
    transition: color ease-in 0.1s;

    &:hover {
      color: ${COLORS.accent};
    }
  }

  @media screen and (max-width: ${BREAKPOINT}) {
    font-size: ${TYPOGRAPHY.medium};
    margin: ${spacing(4)} 0;
  }
`;

export const HeroContentItem = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  margin: ${spacing(2)} 0;

  svg {
    color: ${COLORS.accent};
    height: 32px;
    margin-right: ${spacing(2)};
    min-width: 32px;
    width: 32px;
  }
`;
