import styled from "styled-components";

import { spacing } from "../../../theme/spacing";

export const MapStyled = styled.iframe`
  border: none;
  height: ${spacing(37)};
  margin-top: ${spacing(2)};
  max-width: ${spacing(50)};
  width: 100%;
`;
