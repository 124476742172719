import { FaTimes } from "react-icons/fa";
import styled from "styled-components";

import { BREAKPOINT } from "../../consts/consts";
import { COLORS } from "../../theme/colors";
import { spacing } from "../../theme/spacing";

export const ModalStyled = styled.div`
  background-color: ${COLORS.light2};
  border-radius: ${spacing(1)};
  height: ${spacing(62)};
  left: 0;
  margin: 0 auto;
  max-width: ${spacing(87)};
  overflow: auto;
  padding: ${spacing(4)};
  position: fixed;
  right: 0;
  top: ${spacing(12)};
  width: 100%;
  z-index: 5;

  a {
    color: ${COLORS.dark};
  }

  h2 {
    margin: 0 0 ${spacing(4)} 0;
  }

  @media screen and (max-width: ${BREAKPOINT}) {
    width: calc(100% - 32px);
    height: calc(100vh - 100px);
    top: ${spacing(6)};
  }
`;

export const ModalOverlay = styled.div`
  background-color: rgba(0,0,0,0.5);
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 4;
`;

export const ModalContent = styled.div`
  position: relative;
`;

export const ModalClose = styled(FaTimes)`
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
`;
