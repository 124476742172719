import styled from "styled-components";

import { COLORS } from "../../../theme/colors";
import { TYPOGRAPHY } from "../../../theme/typography";

export const LogoStyled = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
`;

export const Title = styled.h1`
  color: ${COLORS.accent};
  font-family: "Infinite Stroke", sans-serif;
  font-size: ${TYPOGRAPHY.large};
  font-weight: normal;
  margin: 0;
  padding: 0;
`;

export const SubTitle = styled.h2`
  color: ${COLORS.white};
  font-size: ${TYPOGRAPHY.medium};
  margin: 0;
  padding: 0;
  text-transform: uppercase;
`;
