import React from "react";

import { Col } from "../../common/col";
import { Container } from "../../common/container";
import { Section } from "../../common/section";
import { HeroContent } from "../hero/hero-content";
import { Form } from "./form";
import { Map } from "./map";

export const Contact = () => (
  <Section id="contact" image="section-1.webp" label="Napisz do nas" title="Kontakt">
    <Container>
      <Col>
        <Form />
      </Col>
      <Col>
        <HeroContent invert={true} />
        <Map />
      </Col>
    </Container>
  </Section>
);
