import styled from "styled-components";

import { BREAKPOINT, IMAGES_PATH } from "../../consts/consts";
import { COLORS } from "../../theme/colors";
import { spacing } from "../../theme/spacing";

export const SectionStyled = styled.section`
  background-color: ${COLORS.light3};
  padding-bottom: ${spacing(7.5)};
  position: relative;

  @media screen and (max-width: ${BREAKPOINT}) {
    padding-bottom: ${spacing(4)};
  }
`;

export const SectionImage = styled.div`
  background: ${({ src }) => `url(${IMAGES_PATH}/${src})`};
  background-position: center;
  background-size: cover;
  filter: brightness(0.7);
  height: ${spacing(37.5)};
  object-fit: cover;
  width: 100%;
`;
