import React from "react";

import {
  PhoneHeaderInner,
  PhoneHeaderSeparator,
  PhoneHeaderStyled,
} from "./phone-header.styled";

export const PhoneHeader = () => (
  <PhoneHeaderStyled>
    <PhoneHeaderInner>Zamów</PhoneHeaderInner>
    <PhoneHeaderSeparator
      data-aos="zoom-in"
      data-aos-delay="150"
      data-aos-duration="400"
    />
  </PhoneHeaderStyled>
);
