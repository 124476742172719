import React from "react";

import { DishOfTheDay } from "../dish-of-the-day";
import {
  HeroBg,
  HeroContainer, HeroContentContainer, HeroStyled,
} from "./hero.styled";
import { HeroContent } from "./hero-content";

export const Hero = () => (
  <HeroStyled id="header">
    <HeroBg />
    <HeroContainer>
      <HeroContentContainer>
        <HeroContent />
      </HeroContentContainer>
      <DishOfTheDay />
    </HeroContainer>
  </HeroStyled>
);
