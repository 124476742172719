import styled from "styled-components";

import { BREAKPOINT } from "../../consts/consts";
import { spacing } from "../../theme/spacing";

export const ContainerStyled = styled.div`
  align-items: flex-start;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  max-width: ${spacing(150)};
  width: 100%;

  @media screen and (max-width: ${BREAKPOINT}) {
    flex-direction: column;
  }
`;
