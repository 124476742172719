import React from "react";

import {
  MenuName, MenuPrice, MenuStyled, MenuWeight,
} from "./menu.styled";

export const Menu = ({ menu }) => (
  <MenuStyled cellPadding={0} cellSpacing={0} data-aos="fade-in">
    <tbody>
      {menu.map(({ name, price, weight }) => (
        <tr key={`${name}-${weight}-${price}`}>
          <MenuName>{name}</MenuName>
          <MenuWeight>{weight}</MenuWeight>
          <MenuPrice>{price}</MenuPrice>
        </tr>
      ))}
    </tbody>
  </MenuStyled>
);
