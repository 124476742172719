import React from "react";

import { MAP_URL } from "../../../consts/consts";
import { MapStyled } from "./map.styled";

export const Map = () => (
  <MapStyled
    allowFullScreen={false}
    data-aos="fade-in"
    loading="lazy"
    referrerPolicy="no-referrer-when-downgrade"
    src={MAP_URL}
    title="map"
  />
);
