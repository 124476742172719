import styled from "styled-components";

import { BREAKPOINT } from "../../../consts/consts";
import { spacing } from "../../../theme/spacing";

export const FormStyled = styled.form`
  max-width: ${spacing(75)};
  width: 100%;

  @media screen and (max-width: ${BREAKPOINT}) {
    margin-top: ${spacing(-10)};
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;
