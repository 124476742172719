import styled, { css } from "styled-components";

import { COLORS } from "../../theme/colors";
import { spacing } from "../../theme/spacing";
import { TYPOGRAPHY } from "../../theme/typography";

const InputCss = css`
  align-items: center;
  background-color: ${COLORS.light2};
  border: ${({ hasError }) => (hasError ? `${spacing(0.25)} solid red` : "none")};
  border-bottom: ${({ hasError }) => (hasError ? `${spacing(0.25)} solid red` : `${spacing(0.25)} solid ${COLORS.dark}`)};
  border-radius: 0;
  color: ${COLORS.dark};
  display: flex;
  font-family: 'Arimo', sans-serif;
  font-size: ${TYPOGRAPHY.small};
  height: ${spacing(7)};
  justify-content: flex-start;
  margin: ${spacing(2.5)} 0;
  padding: 0 ${spacing(2)};
  width: 100%;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: ${({ hasError }) => (hasError ? "red" : COLORS.dark)};
    font-family: 'Arimo', sans-serif;
    font-size: ${TYPOGRAPHY.small};
  }
`;

export const InputStyled = styled.input`
  ${InputCss}
`;

export const TextAreaStyled = styled.textarea`
  ${InputCss};

  height: ${spacing(25)};
  padding: ${spacing(2)};
`;

export const InputError = styled.p`
  color: red;
  display: flex;
  font-size: ${TYPOGRAPHY.small};
  justify-content: flex-start;
`;
