import "aos/dist/aos.css";
import "react-toastify/dist/ReactToastify.css";

import AOS from "aos";
import React, { useEffect } from "react";
import { ToastContainer } from "react-toastify";

import { AppStyled } from "./app.styled";
import { Footer } from "./common/footer";
import { Header } from "./common/header";
import { Contact } from "./components/contact/contact";
import { DelicatessenMenu } from "./components/delicatessen-menu";
import { Hero } from "./components/hero";
import { LunchMenu } from "./components/lunch-menu";

export const App = () => {
  useEffect(() => {
    AOS.init({ duration: 700 });
  }, []);

  return (
    <AppStyled>
      <ToastContainer position="bottom-right" />
      <Header />
      <Hero />
      <LunchMenu />
      <DelicatessenMenu />
      <Contact />
      <Footer />
    </AppStyled>
  );
};
