import { spacing } from "../theme/spacing";

export const PUBLIC_PATH = process.env.PUBLIC_URL || window.location.origin;
export const IMAGES_PATH = `${PUBLIC_PATH}/images`;

export const FB_URL = "https://www.facebook.com/restauracjaolek";

export const MAP_URL = "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d612.9847953145746!2d16.627439!3d52.08083700000001!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4705b0fd7bac0fc9%3A0x3f5341ceda184133!2sOlek.%20Restauracja!5e0!3m2!1sen!2sus!4v1699448330138!5m2!1sen!2sus";

export const BREAKPOINT = spacing(150);

export const PHONE_1 = "65 512 25 52";
export const PHONE_2 = "604 571 876";
export const EMAIL = "info@restauracjaolek.pl";

export const FORM_URL = "https://submit-form.com/1jdZC0ZGH";
