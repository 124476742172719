import React from "react";

import {
  ModalClose, ModalContent, ModalOverlay, ModalStyled,
} from "./modal.styled";

const cookies = [
  { items: [], label: "Serwis nie zbiera w sposób automatyczny żadnych informacji, z wyjątkiem informacji zawartych w plikach cookies." },
  { items: [], label: "Pliki cookies (tzw. „ciasteczka”) stanowią dane informatyczne, w szczególności pliki tekstowe, które przechowywane są w urządzeniu końcowym Użytkownika Serwisu i przeznaczone są do korzystania ze stron internetowych Serwisu. Cookies zazwyczaj zawierają nazwę strony internetowej, z której pochodzą, czas przechowywania ich na urządzeniu końcowym oraz unikalny numer." },
  { items: [], label: "Podmiotem zamieszczającym na urządzeniu końcowym Użytkownika Serwisu pliki cookies oraz uzyskującym do nich dostęp jest operator Serwisu: Restauracja OLEK z siedzibą pod adresem: Kościan, ul. Wielichowska 53." },
  { items: ["a) dostosowania zawartości stron internetowych Serwisu do preferencji Użytkownika oraz optymalizacji korzystania ze stron internetowych; w szczególności pliki te pozwalają rozpoznać urządzenie Użytkownika Serwisu i odpowiednio wyświetlić stronę internetową, dostosowaną do jego indywidualnych potrzeb;", "b) tworzenia statystyk, które pomagają zrozumieć, w jaki sposób Użytkownicy Serwisu korzystają ze stron internetowych, co umożliwia ulepszanie ich struktury i zawartości;"], label: "Pliki cookies wykorzystywane są w celu:" },
  { items: [], label: "W ramach Serwisu stosowane są dwa zasadnicze rodzaje plików cookies: „sesyjne” (session cookies) oraz „stałe” (persistent cookies). Cookies „sesyjne” są plikami tymczasowymi, które przechowywane są w urządzeniu końcowym Użytkownika do czasu wylogowania, opuszczenia strony internetowej lub wyłączenia oprogramowania (przeglądarki internetowej). „Stałe” pliki cookies przechowywane są w urządzeniu końcowym Użytkownika przez czas określony w parametrach plików cookies lub do czasu ich usunięcia przez Użytkownika." },
  {
    items: [
      "a) „niezbędne” pliki cookies, umożliwiające korzystanie z usług dostępnych w ramach Serwisu, np. uwierzytelniające pliki cookies wykorzystywane do usług wymagających uwierzytelniania w ramach Serwisu;",
      "b) pliki cookies służące do zapewnienia bezpieczeństwa, np. wykorzystywane do wykrywania nadużyć w zakresie uwierzytelniania w ramach Serwisu;",
      "c) „wydajnościowe” pliki cookies, umożliwiające zbieranie informacji o sposobie korzystania ze stron internetowych Serwisu;",
      "d) „funkcjonalne” pliki cookies, umożliwiające „zapamiętanie” wybranych przez Użytkownika ustawień i personalizację interfejsu Użytkownika, np. w zakresie wybranego języka lub regionu, z którego pochodzi Użytkownik, rozmiaru czcionki, wyglądu strony internetowej itp.;",
    ],
    label: "W ramach Serwisu stosowane są następujące rodzaje plików cookies:",
  },
  { items: [], label: "W wielu przypadkach oprogramowanie służące do przeglądania stron internetowych (przeglądarka internetowa) domyślnie dopuszcza przechowywanie plików cookies w urządzeniu końcowym Użytkownika. Użytkownicy Serwisu mogą dokonać w każdym czasie zmiany ustawień dotyczących plików cookies. Ustawienia te mogą zostać zmienione w szczególności w taki sposób, aby blokować automatyczną obsługę plików cookies w ustawieniach przeglądarki internetowej bądź informować o ich każdorazowym zamieszczeniu w urządzeniu Użytkownika Serwisu. Szczegółowe informacje o możliwości i sposobach obsługi plików cookies dostępne są w ustawieniach oprogramowania (przeglądarki internetowej)." },
  { items: [], label: "Operator Serwisu informuje, że ograniczenia stosowania plików cookies mogą wpłynąć na niektóre funkcjonalności dostępne na stronach internetowych Serwisu." },
  { items: [], label: "Pliki cookies zamieszczane są w urządzeniu końcowym Użytkownika Serwisu i wykorzystywane mogą być również przez współpracujących z operatorem Serwisu reklamodawców oraz partnerów." },
  { items: [], label: "Więcej informacji na temat plików cookies dostępnych jest w sekcji „Pomoc” w menu przeglądarki internetowej lub na stronie&nbsp;" },
];

export const Modal = ({ handleClose }) => (
  <>
    <ModalOverlay onClick={handleClose} />
    <ModalStyled>
      <ModalContent>
        <ModalClose onClick={handleClose} />
        <h2>Polityka plików cookies</h2>
        <ol>
          {cookies.map(({ label, items }) => (
            <li key={label}>
              {label}
              {items.map((item) => (
                <React.Fragment key={item}>
                  <br />
                  {item}
                </React.Fragment>
              ))}
            </li>
          ))}
          <a href="http://wszystkoociasteczkach.pl/" rel="noreferrer" target="_blank">http://wszystkoociasteczkach.pl</a>
        </ol>
      </ModalContent>
    </ModalStyled>
  </>
);
