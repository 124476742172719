import styled from "styled-components";

import { BREAKPOINT } from "../../consts/consts";
import { COLORS } from "../../theme/colors";
import { spacing } from "../../theme/spacing";
import { TYPOGRAPHY } from "../../theme/typography";

export const HeaderStyled = styled.header`
  align-items: center;
  background-color: ${({ $isNavBackground, $isOpen }) => $isNavBackground || $isOpen ? COLORS.dark : "transparent"};
  display: flex;
  height: ${spacing(11)};
  left: 0;  
  padding: 0 ${spacing(2)};
  position: fixed;
  top: 0;
  transition: ${({ $isOpen }) => $isOpen ? "none" : "background-color 0.2s ease-in"};
  width: 100%;
  z-index: 3;

  ul {
    display: flex;
    justify-content: center;
    padding: 0;

    @media screen and (max-width: ${BREAKPOINT}) {
      flex-direction: column;
      align-items: center;
      height: 100%;
    }
  }

  ul li {
    display: flex;
    margin: 0;
    padding: 0%;
  }

  ul li button {
    align-items: center;
    background-color: transparent;
    border: none;
    border-bottom: ${spacing(0.5)} solid transparent;
    border-top: ${spacing(0.5)} solid transparent;
    color: ${COLORS.white};
    cursor: pointer;
    display: flex;
    font-size: ${spacing(2.5)};
    font-weight: bold;
    height: ${spacing(11)};
    padding: 0 ${spacing(2.5)};
    text-decoration: none;
    transition: all ease-in 0.1s;

    &:hover {
      border-bottom: ${spacing(0.5)} solid ${COLORS.accent};
      color: ${COLORS.accent};
    }
  }
`;

export const HeaderInner = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const HeaderNav = styled.nav`
  align-items: center;
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: ${BREAKPOINT}) {
    display: ${({ $isOpen }) => ($isOpen ? "flex" : "none")};
    position: fixed;
    top: ${spacing(11)};
    left: 0;
    width: 100%;
    height: calc(100vh - 88px);
    background-color: ${COLORS.dark};
    z-index: 2;
    flex-direction: column;
  }
`;

export const FbLink = styled.a`
  align-items: center;
  border-bottom: ${spacing(0.5)} solid transparent;
  border-top: ${spacing(0.5)} solid transparent;
  color: ${COLORS.white};
  display: flex;
  font-size: ${TYPOGRAPHY.large};
  font-weight: bold;
  height: ${spacing(11)};
  padding: 0 ${spacing(3)};
  text-decoration: none;
  transition: all ease-in 0.1s;

  &:hover {
    color: ${COLORS.accent};
  }
`;

export const HamburgerWrapper = styled.div`
  color: ${COLORS.white};
  display: none;

  @media screen and (max-width: ${BREAKPOINT}) {
    display: flex;
  }
`;
