import styled from "styled-components";

import { BREAKPOINT } from "../../consts/consts";
import { COLORS } from "../../theme/colors";
import { spacing } from "../../theme/spacing";
import { TYPOGRAPHY } from "../../theme/typography";

export const PhoneStyled = styled.a`
  align-items: center;
  background-color: ${COLORS.white};
  border: 1px solid ${COLORS.dark};
  border-radius: ${spacing(1)};
  color: ${COLORS.dark};
  display: flex;
  font-size: ${TYPOGRAPHY.large};
  font-weight: bold;
  justify-content: center;
  margin: ${spacing(6)} ${spacing(3)};
  padding: ${spacing(4)};
  text-decoration: none;
  transition: color ease-in 0.1s;
  
  &:hover {
    color: ${COLORS.accent};
  }

  @media screen and (max-width: ${BREAKPOINT}) {
    font-size: ${TYPOGRAPHY.medium};
    padding: ${spacing(4)};
    margin: ${spacing(4)} auto;
    max-width: ${spacing(36)};
    width: 100%;
  }
`;

export const PhoneIcon = styled.div`
  align-items: center;
  background-color: ${COLORS.dark};
  border-radius: 50%;
  color: ${COLORS.accent};
  display: flex;
  height: ${spacing(8)};
  justify-content: center;
  margin-right: ${spacing(2)};
  width: ${spacing(8)};

  @media screen and (max-width: ${BREAKPOINT}) {
    width: ${spacing(6)};
    height: ${spacing(6)};
  }
`;
