import React, { useState } from "react";

import { Container } from "../container/container";
import { Modal } from "../modal";
import { CookiesLink, FooterInner, FooterStyled } from "./footer.styled";

export const Footer = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const handleClose = () => setIsModalVisible(!isModalVisible);

  return (
    <FooterStyled>
      <Container>
        <FooterInner>
          Copyright ©
          {" "}
          {new Date().getFullYear()}
          {" "}
          Restauracja Olek |
          {" "}
          <CookiesLink onClick={handleClose} type="button">Polityka plików cookies</CookiesLink>
        </FooterInner>
        {isModalVisible && (<Modal handleClose={handleClose} />)}
      </Container>
    </FooterStyled>
  );
};
