import styled from "styled-components";

import { BREAKPOINT } from "../../consts/consts";
import { COLORS } from "../../theme/colors";
import { spacing } from "../../theme/spacing";
import { TYPOGRAPHY } from "../../theme/typography";

export const SectionHeaderStyled = styled.div`
  align-items: center;
  background-color: ${COLORS.dark};
  border: none;
  box-sizing: border-box;
  color: ${COLORS.accent};
  cursor: pointer;
  display: flex;
  font-size: ${TYPOGRAPHY.medium};
  font-weight: bold;
  height: ${spacing(25)};
  justify-content: center;
  margin: 0 auto;
  max-width: ${spacing(87)};
  padding: ${spacing(1)};
  position: relative;
  text-transform: uppercase;
  top: ${spacing(-13.5)};
  width: 100%;
  z-index: 1;

  @media screen and (max-width: ${BREAKPOINT}) {
    height: ${spacing(20)};
    margin: 0 auto;
    width: calc(100% - 32px);
    top: ${spacing(-10)};
  }
`;

export const SectionHeaderInner = styled.div`
  align-items: center;
  border: 1px solid ${COLORS.accent};
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  width: 100%;
`;

export const SectionHeaderTitle = styled.h2`
  color: ${COLORS.white};
  font-size: ${TYPOGRAPHY.small};
  margin: 0;
  padding: 0;
`;

export const SectionHeaderSeparator = styled.div`
  background-color: ${COLORS.white};
  content: "";
  display: block;
  height: ${spacing(0.25)};
  margin: ${spacing(2)} auto;
  width: ${spacing(7.5)};
`;

export const SectionHeaderLabel = styled.h3`
  color: ${COLORS.accent};
  font-family: "Infinite Stroke", sans-serif;
  font-size: ${TYPOGRAPHY.xlarge};
  font-weight: normal;
  margin: 0;
  padding: 0;
  text-transform: none;

  @media screen and (max-width: ${BREAKPOINT}) {
    font-size: ${TYPOGRAPHY.large};
  }
`;
