import styled from "styled-components";

import { BREAKPOINT } from "../../consts/consts";
import { COLORS } from "../../theme/colors";
import { spacing } from "../../theme/spacing";
import { TYPOGRAPHY } from "../../theme/typography";

export const ButtonStyled = styled.button`
  align-items: center;
  background-color: ${COLORS.dark};
  border: none;
  color: ${COLORS.white};
  cursor: pointer;
  display: flex;
  font-size: ${spacing(2.5)};
  font-weight: bold;
  justify-content: center;
  padding: ${spacing(2)};
  text-transform: uppercase;
  transition: color ease-in 0.1s;
  width: 250px;

  &:hover,
  &:active,
  &:focus {
    color: ${COLORS.accent};
  }

  svg {
    margin-right: ${spacing(2)};
  }

  @media screen and (max-width: ${BREAKPOINT}) {
    font-size: ${TYPOGRAPHY.small};
    padding: ${spacing(2)};
  }
`;
