import { Turn as Hamburger } from "hamburger-react";
import React, { useEffect, useState } from "react";
import { FaFacebook } from "react-icons/fa";

import { FB_URL } from "../../consts/consts";
import { handleClickScroll } from "../../utils/utils";
import { Container } from "../container";
import {
  FbLink, HamburgerWrapper, HeaderInner, HeaderNav, HeaderStyled,
} from "./header.styled";
import { Logo } from "./logo";

const nav = [
  {
    id: "lunch",
    label: "Menu obiadowe",
  },
  {
    id: "delicatessen",
    label: "Menu garmażerka",
  },
  {
    id: "contact",
    label: "Kontakt",
  },
];

export const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isNavBackground, setIsNavBackground] = useState(false);

  const changeNavBg = () => setIsNavBackground(window.scrollY >= 100);

  useEffect(() => {
    window.addEventListener("scroll", changeNavBg);

    return () => {
      window.removeEventListener("scroll", changeNavBg);
    };
  }, []);

  return (
    <HeaderStyled $isNavBackground={isNavBackground} $isOpen={isOpen}>
      <Container>
        <HeaderInner>
          <Logo />
          <HeaderNav $isOpen={isOpen}>
            <ul>
              {nav.map(({ label, id }) => (
                <li key={label}>
                  <button onClick={() => { handleClickScroll(id); setIsOpen(false); }} type="button">
                    {label}
                  </button>
                </li>
              ))}
            </ul>
            <FbLink href={FB_URL} rel="noreferrer" target="_blank">
              <FaFacebook />
            </FbLink>
          </HeaderNav>
          <HamburgerWrapper onClick={() => setIsOpen(!isOpen)}>
            <Hamburger toggled={isOpen} />
          </HamburgerWrapper>
        </HeaderInner>
      </Container>
    </HeaderStyled>
  );
};
