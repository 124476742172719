import React from "react";

import {
  SectionHeaderInner,
  SectionHeaderLabel,
  SectionHeaderSeparator,
  SectionHeaderStyled,
  SectionHeaderTitle,
} from "./section-header.styled";

export const SectionHeader = ({ title, label }) => (
  <SectionHeaderStyled>
    <SectionHeaderInner>
      <SectionHeaderTitle>{title}</SectionHeaderTitle>
      <SectionHeaderSeparator
        data-aos="zoom-in"
        data-aos-delay="150"
        data-aos-duration="400"
      />
      <SectionHeaderLabel data-aos="fade-up" data-aos-offset="-100">{label}</SectionHeaderLabel>
    </SectionHeaderInner>
  </SectionHeaderStyled>
);
