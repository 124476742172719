import styled from "styled-components";

import { BREAKPOINT } from "../../consts/consts";
import { COLORS } from "../../theme/colors";
import { spacing } from "../../theme/spacing";

export const GalleryStyled = styled.div`
  width: 100%;

  ul.thumbs {
    padding: 0;
  }

  ul.control-dots {
    background-color: rgba(0,0,0,0.2);
    margin: 0;
    padding: 10px 0;
  }

  ul.control-dots li.dot {
    background-color: ${COLORS.white};
    height: ${spacing(1.5)};
    opacity: 1;
    width: ${spacing(1.5)};
  }

  ul.control-dots  li.dot.selected {
    background-color: ${COLORS.accent};
  }

  .carousel .thumbs-wrapper {
    margin-top: 0;
  }

  .carousel .thumb.selected, .carousel .thumb:hover {
    border: ${spacing(0.25)} solid ${COLORS.accent};
  }

  .carousel .thumb {
    cursor: pointer;
  }

  .slider::before {
    content: '';
    height: 100%;
    left: 100%;
    position: absolute;
    top: 0;
    width: 10000%;
  }

  @media screen and (max-width: ${BREAKPOINT}) {
    margin-top: ${spacing(2)};
  }
`;
