import { Formik } from "formik";
import React from "react";
import { FaPaperPlane } from "react-icons/fa";

import { Button } from "../../../common/button";
import { Input } from "../../../common/input";
import { ButtonContainer, FormStyled } from "./form.styled";
import { useForm } from "./use-form";

export const Form = () => {
  const { onSubmit, validate, initialValues } = useForm();

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validate={validate}
    >
      {({ handleSubmit, isSubmitting }) => (
        <FormStyled onSubmit={handleSubmit}>
          <Input
            label="Imię i nazwisko*"
            name="name"
            type="text"
          />
          <Input
            label="E-mail*"
            name="email"
            type="email"
          />
          <Input
            label="Telefon*"
            name="phone"
            type="text"
          />
          <Input
            label="Wiadomość*"
            name="message"
            textarea
            type="text"
          />
          <ButtonContainer data-aos="fade-up">
            <Button disabled={isSubmitting} type="submit">
              <FaPaperPlane />
              Wyślij
            </Button>
          </ButtonContainer>
        </FormStyled>
      )}
    </Formik>
  );
};
