import { toast } from "react-toastify";

import { FORM_URL } from "../../../consts/consts";

const initialValues = {
  email: "",
  message: "",
  name: "",
  phone: "",
};

export const useForm = () => {
  const onSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      setSubmitting(true);
      const res = await fetch(FORM_URL, {
        body: JSON.stringify(values),
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
        redirect: "manual",
      });
      if (res) {
        resetForm();
        toast.success("Wysłano formularz");
      }
      setSubmitting(false);
    } catch (e) {
      setSubmitting(false);
      toast.error("Wystąpił błąd");
    }
  };

  const validate = (values) => {
    const errors = {};

    if (!values.name) {
      errors.name = "Pole wymagane.";
    }

    if (!values.email) {
      errors.email = "Pole wymagane.";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
    ) {
      errors.email = "Wpisz poprawny e-mail.";
    }

    if (!values.phone) {
      errors.phone = "Pole wymagane.";
    }

    if (!values.message) {
      errors.message = "Pole wymagane.";
    }
    return errors;
  };

  return { initialValues, onSubmit, validate };
};
