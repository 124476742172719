import React from "react";

import { Button } from "../../common/button";
import { FB_URL } from "../../consts/consts";
import {
  DishOfTheDayButtonContainer,
  DishOfTheDayContent,
  DishOfTheDayHeader,
  DishOfTheDayItem,
  DishOfTheDayStyled,
} from "./dish-of-the-day.styled";

export const DishOfTheDay = () => (
  <DishOfTheDayStyled data-aos="fade-up">
    <DishOfTheDayContent>
      <DishOfTheDayHeader>Danie dnia</DishOfTheDayHeader>
      Sprawdź naszą specjalną dwudaniową ofertę dnia na Facebooku
      <DishOfTheDayItem>
        <span>Zupa dnia</span>
        <br />
        <strong>8zł</strong>
      </DishOfTheDayItem>
      <DishOfTheDayItem>
        <span>Drugie danie</span>
        <br />
        <strong>22zł</strong>
      </DishOfTheDayItem>
      <DishOfTheDayButtonContainer>
        <Button onClick={() => window.open(FB_URL)}>
          Sprawdź
        </Button>
      </DishOfTheDayButtonContainer>
    </DishOfTheDayContent>
  </DishOfTheDayStyled>
);
