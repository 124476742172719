import React from "react";

import { PHONE_1, PHONE_2 } from "../../consts/consts";
import { Phone } from "../phone";
import { PhoneHeader } from "../phone-header";
import { OrderStyled } from "./order.styled";

export const Order = () => (
  <OrderStyled>
    <PhoneHeader />
    <Phone label={PHONE_1} />
    <Phone label={PHONE_2} />
  </OrderStyled>
);
