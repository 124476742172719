import styled from "styled-components";

import { COLORS } from "../../theme/colors";
import { spacing } from "../../theme/spacing";
import { TYPOGRAPHY } from "../../theme/typography";

export const PhoneHeaderStyled = styled.div`
  align-items: center;
  color: ${COLORS.dark};
  display: flex;
  font-size: ${TYPOGRAPHY.large};
  font-weight: bold;
  justify-content: center;
  margin: 0;
  padding: 0%;
  position: relative;
  width: 100%;
`;

export const PhoneHeaderSeparator = styled.div`
  background-color: ${COLORS.dark};
  content: "";
  display: block;
  height: ${spacing(0.5)};
  position: absolute;
  width: 100%;
`;

export const PhoneHeaderInner = styled.h4`
  align-items: center;
  background-color: ${COLORS.light3};
  display: flex;
  justify-content: center;
  margin: 0;
  padding: ${spacing(2)};
  z-index: 1;
`;
