import { useFormikContext } from "formik";
import React from "react";

import { InputError, InputStyled, TextAreaStyled } from "./input.styled";

export const Input = ({
  name, label, type, textarea = null,
}) => {
  const {
    values, touched, errors, handleChange, handleBlur,
  } = useFormikContext();
  const hasError = errors[name] && touched[name];

  return (
    <>
      {textarea ? (
        <TextAreaStyled
          hasError={hasError}
          name={name}
          onBlur={handleBlur}
          onChange={handleChange}
          placeholder={label}
          type={type}
          value={values[name]}
        />
      ) : (
        <InputStyled
          hasError={hasError}
          name={name}
          onBlur={handleBlur}
          onChange={handleChange}
          placeholder={label}
          type={type}
          value={values[name]}
        />
      )}

      {hasError && (
        <InputError>
          {errors[name]}
        </InputError>
      )}
    </>
  );
};
