import styled from "styled-components";

import { BREAKPOINT, IMAGES_PATH } from "../../consts/consts";
import { spacing } from "../../theme/spacing";

export const HeroBg = styled.div`
  background-attachment: fixed;
  background-image: ${() => `url(${IMAGES_PATH}/bg.webp)`};
  background-position: ${spacing(-2)} 0;
  background-repeat: no-repeat;
  background-size: cover;
  filter: brightness(0.5);
  height: ${spacing(112)};
  padding-top: ${spacing(11)};
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;

  @media screen and (max-width: ${BREAKPOINT}) {
    height: ${spacing(94)};
    background-attachment: scroll;
  }
`;
export const HeroStyled = styled.div`
  height: ${spacing(112)};
  padding-top: ${spacing(11)};
  position: relative;
  top: 0;
  width: 100%;
  z-index: 1;

  @media screen and (max-width: ${BREAKPOINT}) {
    height: ${spacing(94)};
    background-attachment: scroll;
  }
`;

export const HeroContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  max-width: ${spacing(150)};
  position: relative;
  width: 100%;
`;

export const HeroContentContainer = styled.div`
  @media screen and (max-width: ${BREAKPOINT}) {
    display: none;
  }
`;
