import React from "react";

import { SectionHeader } from "../section-header";
import { SectionImage, SectionStyled } from "./section.styled";

export const Section = ({
  id, label, title, image, children,
}) => (
  <SectionStyled id={id}>
    {image && (
      <SectionImage src={image} />
    )}
    <SectionHeader label={label} title={title} />
    {children}
  </SectionStyled>
);
