import "react-responsive-carousel/lib/styles/carousel.min.css";

import React from "react";
import { Carousel } from "react-responsive-carousel";

import { IMAGES_PATH } from "../../consts/consts";
import { GalleryStyled } from "./gallery.styled";

export const Gallery = ({ gallery }) => (
  <GalleryStyled data-aos="fade-in">
    <Carousel showStatus={false}>
      {gallery.map((photo) => (
        <img key={photo} alt={photo} src={`${IMAGES_PATH}/gallery/${photo}`} width="100%" />
      ))}
    </Carousel>
  </GalleryStyled>
);
