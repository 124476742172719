import React from "react";
import { FaEnvelope, FaPhone } from "react-icons/fa";

import { EMAIL, PHONE_1, PHONE_2 } from "../../../consts/consts";
import {
  HeroContentItem, HeroContentStyled,
} from "./hero-content.styled";

export const HeroContent = ({ invert = false }) => {
  const dataAos = invert ? "fade-up" : "fade-left";

  return (
    <HeroContentStyled $invert={invert}>
      <p>
        <span data-aos={dataAos}>ul. Wielichowska 53</span>
        <br />
        <span data-aos={dataAos}>64-000, Kościan</span>
      </p>
      <HeroContentItem data-aos={dataAos} data-aos-delay="300">
        <FaPhone />
        <strong>
          <a href={`tel:${PHONE_1}`}>{PHONE_1}</a>
        </strong>
      </HeroContentItem>
      <HeroContentItem data-aos={dataAos} data-aos-delay="400">
        <FaPhone />
        <strong>
          <a href={`tel:${PHONE_2}`}>{PHONE_2}</a>
        </strong>
      </HeroContentItem>
      <HeroContentItem data-aos={dataAos} data-aos-delay="500">
        <FaEnvelope />
        <strong><a href={`mail:${EMAIL}`}>{EMAIL}</a></strong>
      </HeroContentItem>
    </HeroContentStyled>
  );
};
