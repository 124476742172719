import React from "react";

import { handleClickScroll } from "../../../utils/utils";
import { LogoStyled, SubTitle, Title } from "./logo.styled";

export const Logo = () => (
  <LogoStyled onClick={() => handleClickScroll("header")}>
    <Title>Olek</Title>
    <SubTitle>Restauracja</SubTitle>
  </LogoStyled>
);
