import React from "react";

import { Col } from "../../common/col";
import { Container } from "../../common/container";
import { Gallery } from "../../common/gallery";
import { Menu } from "../../common/menu";
import { Order } from "../../common/order";
import { Section } from "../../common/section";

const delicatessenMenu = [
  { name: "Galantynka z kurczaka", price: "9,00zł", weight: "50/50g" },
  { name: "Ozór wołowy", price: "12,00zł", weight: "50/50g" },
  { name: "Ozór wieprzowy", price: "9,50zł", weight: "50/50g" },
  { name: "Polędwica drobiowa", price: "8,50zł", weight: "50/50g" },
  { name: "Rolada szpinakowa", price: "9,00zł", weight: "50/50g" },
  { name: "Szparagi w szynce", price: "9,50zł", weight: "50/50g" },
  { name: "Jajko w szynce", price: "9,00zł", weight: "50/50g" },
  { name: "Schab gotowany", price: "9,50zł", weight: "50/50g" },
  { name: "Pierś z kurczaka", price: "9,50zł", weight: "50/50g" },
  { name: "Karp", price: "18,00zł", weight: "80/50g" },
  { name: "Łosoś", price: "19,50zł", weight: "80/50g" },
  { name: "Sandacz", price: "19,00zł", weight: "70/50g" },
  { name: "Ryba w sosie greckim", price: "13,00zł", weight: "80/80g" },
  { name: "Tatar", price: "7,50zł", weight: "50g" },
  { name: "Tatar", price: "15,00zł", weight: "100g" },
  { name: "Sałatka jajeczna", price: "5,50zł", weight: "100g" },
  { name: "Śledz w oleju z cebulką", price: "5,00zł", weight: "50/30g" },
  { name: "Galaretka drobiowa", price: "9,00zł", weight: "200g" },
  { name: "Galart wieprzowy", price: "5,00zł", weight: "100g" },
];

const delicatessenGallery = [
  "delicatessen/1.webp", "delicatessen/2.webp", "delicatessen/3.webp", "delicatessen/4.webp", "delicatessen/5.webp",
];

export const DelicatessenMenu = () => (
  <Section id="delicatessen" image="section-2.webp" label="Wyroby garmazeryjne" title="Nasze menu">
    <Container>
      <Col>
        <Menu menu={delicatessenMenu} />
      </Col>
      <Col>
        <Gallery gallery={delicatessenGallery} />
        <Order />
      </Col>
    </Container>
  </Section>
);
