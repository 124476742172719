import React from "react";

import { Col } from "../../common/col";
import { Container } from "../../common/container";
import { Gallery } from "../../common/gallery";
import { Menu } from "../../common/menu";
import { Order } from "../../common/order";
import { Section } from "../../common/section";

const lunchMenu = [
  { name: "Zupa dnia", price: "8,00zł", weight: "500g" },
  { name: "Flaczki wieprzowe", price: "13,50zł", weight: "350g" },
  { name: "Flaczki wieprzowe", price: "17,00zł", weight: "500g" },
  { name: "Szaszłyk z karkówki", price: "9,00zł", weight: "100g" },
  { name: "Golonka gotowana", price: "8,00zł", weight: "100g" },
  { name: "Golonka pieczona z/k", price: "9,00zł", weight: "100g" },
  { name: "Rolada z pieczarkami", price: "15,00zł", weight: "130g" },
  { name: "Kotlet de Vollaile", price: "15,00zł", weight: "130g" },
  { name: "Medalion z drobiu", price: "9,00zł", weight: "100g" },
  { name: "Krokiet z grzybami", price: "8,50zł", weight: "120g" },
  { name: "Kotlet mielony", price: "9,00zł", weight: "100g" },
  { name: "Stek z w/p cebulką", price: "15,00zł", weight: "120g" },
  { name: "Zraz w/p w sosie", price: "15,00zł", weight: "110g" },
  { name: "Zraz wołowy", price: "17,00zł", weight: "110g" },
  { name: "Roladka w szynce", price: "17,00zł", weight: "130g" },
  { name: "Bitki w sosie paprykowym", price: "12,00zł", weight: "130g" },
  { name: "Roladka królewska", price: "15,00zł", weight: "130g" },
  { name: "Rolsy z kurczakiem", price: "9,00zł", weight: "130g" },
  { name: "Mintaj w panierce", price: "15,00zł", weight: "120g" },
  { name: "Kotlet szwajcarski", price: "15,00zł", weight: "130g" },
  { name: "Wątróbka wieprzowa", price: "15,00zł", weight: "200g" },
  { name: "Gołąbek", price: "9,00zł", weight: "150g" },
  { name: "Kurczak z rożna", price: "6,00zł", weight: "100g" },
  { name: "Fasolka po bretońsku", price: "5,00zł", weight: "100g" },
  { name: "Bigos staropolski", price: "5,00zł", weight: "100g" },
  { name: "Gulasz wp.", price: "6,00zł", weight: "100g" },
  { name: "Ziemniaki z wody", price: "4,00zł", weight: "200g" },
  { name: "Frytki", price: "8,00zł", weight: "150g" },
  { name: "Surówki", price: "4,00zł", weight: "100g" },
  { name: "Sos węgierski", price: "3,00zł", weight: "80g" },
  { name: "Sos pieczeniowy", price: "3,00zł", weight: "80g" },
];

const lunchGallery = [
  "lunch/1.webp", "lunch/2.webp", "lunch/3.webp", "lunch/4.webp", "lunch/5.webp",
];

export const LunchMenu = () => (
  <>
    <div id="lunch" style={{ position: "relative", top: "-300px" }} />
    <Section label="Dania obiadowe" title="Nasze menu">
      <Container>
        <Col order={1}>
          <Gallery gallery={lunchGallery} />
          <Order />
        </Col>
        <Col order={0}>
          <Menu menu={lunchMenu} />
        </Col>
      </Container>
    </Section>
  </>

);
