import styled from "styled-components";

import { BREAKPOINT } from "../../consts/consts";
import { COLORS } from "../../theme/colors";
import { spacing } from "../../theme/spacing";
import { TYPOGRAPHY } from "../../theme/typography";

export const DishOfTheDayStyled = styled.div`
  background-color: ${COLORS.white};
  height: ${spacing(60)};
  max-width: ${spacing(47)};
  position: absolute;
  right: ${spacing(12)};
  top: ${spacing(6)};
  width: 100%;

  @media screen and (max-width: ${BREAKPOINT}) {
   left: 0;
   right: 0;
   margin: 0 auto;
   width: calc(100% - 64px);
   top: ${spacing(8)};
   height: ${spacing(55)};
  }
`;

export const DishOfTheDayContent = styled.div`
  border: 1px solid ${COLORS.dark};
  display: flex;
  flex-direction: column;
  height: calc(100% - 16px);
  margin: ${spacing(1)};
  padding: ${spacing(3)};
  position: relative;
  width: calc(100% - 16px);

  @media screen and (max-width: ${BREAKPOINT}) {
    padding: ${spacing(2)};
    margin: ${spacing(1)};
  }
`;

export const DishOfTheDayHeader = styled.h4`
  color: ${COLORS.dark};
  font-size: ${TYPOGRAPHY.small};
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;

  &::after {
    background-color: ${COLORS.dark};
    content: "";
    display: block;
    height: ${spacing(0.25)};
    margin: ${spacing(2.5)} auto;
    width: ${spacing(7.5)};
  }
`;

export const DishOfTheDayItem = styled.p`
  color: ${COLORS.dark};
  font-size: ${TYPOGRAPHY.medium};
  margin: ${spacing(4)} 0 0;

  @media screen and (max-width: ${BREAKPOINT}) {
    font-size: ${TYPOGRAPHY.small};
  }
`;

export const DishOfTheDayButtonContainer = styled.div`
  bottom: ${spacing(5)};
  display: flex;
  justify-content: center;
  left: 0;
  margin: ${spacing(4)} auto 0;
  position: absolute;
  right: 0;

  @media screen and (max-width: ${BREAKPOINT}) {
    bottom: ${spacing(3)};
  }
`;
