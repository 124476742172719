import React from "react";
import { FaPhone } from "react-icons/fa";

import { PhoneIcon, PhoneStyled } from "./phone.styled";

export const Phone = ({ label }) => (
  <div data-aos="fade-up">
    <PhoneStyled href={`tel:${label}`}>
      <PhoneIcon>
        <FaPhone />
      </PhoneIcon>
      {label}
    </PhoneStyled>
  </div>

);
