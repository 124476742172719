import styled from "styled-components";

import { BREAKPOINT } from "../../consts/consts";
import { COLORS } from "../../theme/colors";
import { spacing } from "../../theme/spacing";
import { TYPOGRAPHY } from "../../theme/typography";

export const MenuStyled = styled.table`
    border: 0;
    color: ${COLORS.dark};
    font-size: ${spacing(2.5)};
    max-width: ${spacing(75)};
    width: 100%;

    & td {
      border: none;
      padding: ${spacing(1.5)};
    }

    & tr:nth-child(odd) {
      background-color: ${COLORS.light2};
    }

    & tr:last-of-type td {
      border: none;
    }

    @media screen and (max-width: ${BREAKPOINT}) {
      margin-top: ${spacing(-8)};
      font-size: ${TYPOGRAPHY.small};
    }
`;

export const MenuName = styled.td`
  text-align: left;
`;

export const MenuPrice = styled.td`
  font-weight: bold;
  text-align: right;
`;

export const MenuWeight = styled.td`
  font-style: italic;
  text-align: left;
`;
